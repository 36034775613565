<template>
   <footer>
 <div class="phoneHint">
   <a class="tellink" href="tel:+49941830200"><span><i class="fa-solid fa-phone-volume"></i> Benötigen Sie telefonische Beratung?</span></a>
 </div>
     <div class="orangeContainer">
       <div class="row">
         <div class="col-6 columns   large-4 medium-12 small-12">
           <p><strong>Meindl Entsorgungsservice GmbH</strong><br>Baierner Höhe 2<br>93138 Lappersdorf</p>
           <p><a href="tel:0941830200">0941-83020-0</a></p>
           <p><a href="mailto:info@meindl-entsorgung.de"><i class="fa-solid fa-envelope"></i> info@meindl-entsorgung.de</a></p>
        </div>
         <div class="col-3 columns   large-4 medium-6 small-12"><p><strong>Öffnungszeiten</strong></p><p>Parteiverkehr: Mo.- Fr.: 08:00 - 12:00 und 13:00 - 16:00 Uhr<br>(kein Verkauf von Mülltonnen)<br>Telefonzentrale:&nbsp;Mo. - Fr. 8:00 - 16:00 Uhr</p></div>
         <div class="col-3 columns   large-4 medium-6 small-12">
           <p> <a href="https://meindl-entsorgung.de/impressum" target="_blank">Impressum</a>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; <br><a href="https://meindl-entsorgung.de/datenschutz" target="_blank">Datenschutzerklärung</a> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;<br> <a href="https://meindl-entsorgung.de/agb">AGB&nbsp;Meindl&nbsp; &nbsp; &nbsp; &nbsp; </a><br> <a href="https://meindl-entsorgung.de/9-ueber/170-zusatzbedingungen" target="_blank" rel="noopener" >Zusatzbedingungen Kundenportal<br></a> <a href="https://meindl-entsorgung.de/hinweis-abgeben" target="_blank" rel="noopener"> Hinweis Abgeben </a></p>
         </div>
       </div>
       <div class="container">
         <div class="row">
           <div class="columns large-12">
             <p class="versionsInfo">Version: {{ $store.state.version }}</p>
           </div>
         </div>
       </div>
     </div>
   </footer>
</template>
<script>
export default {
  name: "footerView",
  props: {
  }
}
</script>

