<template>

<!-- <img src="@/assets/images/containerdienst.jpg" style="width: 100%; display: block; margin-bottom: 24px;" />-->
  <div class="row" style="min-height: 150px;padding-top: 48px;">
    <div class="columns large-12 medium-12 small-12">

    <div class="zipEntry">
      <div class="row">
        <div class="columns xlarge-12 large-12 medium-12 small-12">
          <h1>Container einfach online bestellen!</h1>
          <div class="allInLine">
            <span>
              Geben Sie hier die Postleitzahl der Lieferadresse ein:
            </span>
            <input type="text" v-model="zip" placeholder="PLZ" @keyup.enter="checkAvailability"/>
            <button class="btn" @click="checkAvailability">Weiter</button>
          </div>

        </div>
      </div>
    </div>

    </div>
  </div>





</template>
<script>
import axios from "axios";
export default {
  name: "Reorder",
  data: function () {
    return {
      zip: "",
      allowedZips: ["93138", "93092", "93093", "93182", "93183", "93096", "93073", "93152", "93083", "93080", "93186", "93188", "93128", "93161", "93105", "93173", "93195", "93197", "93055", "93051", "93049", "93047", "93057", "93053", "93059", "93077", "93087", "93177", "93089", "93090", "93176", "93170", "93179", "93164", "93180", "93095", "93155", "93098", "93099", "93101", "93102", "93104", "84069", "93107", "93109", "93086", "93133", "93345", "93346", "93309", "84085", "93142", "93351", "93342", "93158", "93356", "93192"],
    }
  },
  methods: {
    checkAvailability() {
      if (!this.allowedZips.includes(this.zip)) {
        this.zip = "";
        alert("Leider ist diese Postleitzahl nicht verfügbar.");
      } else {

        this.$store.state.order.zip = this.zip;


      this.$router.push("/type");
      }
    }
  },

};
</script>
